import React, { useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import Slider from "@mui/material/Slider";

const SearchFilter = ({
  filterModal,
  setFilterModal,
  applyFilter,
  resetFilter,
  sliderValue1,
  handleSliderChange1,
  sliderValue2,
  handleSliderChange2,
  minVal1,
  maxVal1,
  title1,
  minVal2,
  maxVal2,
  title2,
  status,
  setStatus,
}) => {
  return (
    <>
      <Modal
        open={filterModal}
        onClose={() => setFilterModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 400,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <p className="fw-bold">Filter</p>
            <IconButton
              aria-label="close"
              onClick={() => setFilterModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>

          <>
            <div className="mb-4">
              <p>{title1}</p>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <p>{sliderValue1[0]}</p>
                <Slider
                  value={sliderValue1}
                  onChange={handleSliderChange1}
                  valueLabelDisplay="off"
                  valueLabelFormat={(value) => `${value}`}
                  min={minVal1}
                  max={maxVal1}
                  step={1}
                />
                <p>{sliderValue1[1]}</p>
              </div>
            </div>

            {title2 && (
              <>
                <div className="mb-4">
                  <p>{title2}</p>
                  <div className="d-flex align-items-center justify-content-between gap-2">
                    <p>{sliderValue2[0]}</p>
                    <Slider
                      value={sliderValue2}
                      onChange={handleSliderChange2}
                      valueLabelDisplay="off"
                      valueLabelFormat={(value) => `${value}`}
                      min={minVal2}
                      max={maxVal2}
                      step={1}
                    />
                    <p>{sliderValue2[1]}</p>
                  </div>
                </div>
              </>
            )}

            <div>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Lead status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Lead status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  variant="standard"
                >
                  <MenuItem value="uncontactable">Uncontactable</MenuItem>
                  <MenuItem value="in_progress">In Progress</MenuItem>
                  <MenuItem value="processed">Processed</MenuItem>
                  <MenuItem value="not_eligible">Not Eligible</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="d-flex align-items-center justify-content-end gap-2 mt-4">
              <Button
                variant="contained"
                color="danger"
                className="text-white"
                onClick={resetFilter}
              >
                Reset
              </Button>
              <Button variant="contained" onClick={applyFilter}>
                Done
              </Button>
            </div>
          </>
        </Box>
      </Modal>
    </>
  );
};

export default SearchFilter;
