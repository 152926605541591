import React from "react";

const NoDataFound = () => {
  return (
    <div className="text-center my-4">
      <p>No data found</p>
    </div>
  );
};

export default NoDataFound;
