import React from "react";
import { Alert, Snackbar } from "@mui/material";

const SnackBar = ({ openSnackbar, setOpenSnackbar }) => {
  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="error"
          sx={{ width: "100%", background: "red", color: "white" }}
        >
          Your account is not active
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackBar;
