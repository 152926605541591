import React, { useContext, useMemo, useState } from "react";
import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import Cookies from "js-cookie";
import { AppContext } from "../context/AppContext";

const ConfirmShare = ({ shareModal, setShareModal, selected, setSelected }) => {
  const { url } = useContext(AppContext);
  //
  const [message, setMessage] = useState("");
  const share = async () => {
    const tcm_user = Cookies.get("tcm_user");
    try {
      const res = await axios.post(
        `${url}/user/lead/share`,
        {
          leadIds: selected,
        },
        {
          headers: {
            token: tcm_user,
          },
        }
      );
      if (res.data.success === true) {
        setShareModal(false);
        setSelected([]);
      } else {
        setMessage(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  useMemo(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);
  return (
    <>
      <Modal
        open={shareModal}
        onClose={() => setShareModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            textAlign: "center",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <h2 className="mb-2">Share {selected.length} leads with TCM?</h2>
          <p className="text-danger mb-2">{message}</p>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <Button color="primary" variant="contained" onClick={share}>
              Yes
            </Button>
            <Button
              color="danger"
              className="text-white"
              onClick={() => setShareModal(false)}
              variant="contained"
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmShare;
