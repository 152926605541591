import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, NavLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../assets/images/logo.png";

const Navbar = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setToggleMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setToggleMenu(false);
  };
  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <Link to="/" className="logo me-auto">
            <img src={logo} alt="logo" />
          </Link>

          {isMobile ? (
            <>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleClick}
              >
                {toggleMenu ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="menu-item"
              >
                <MenuItem onClick={handleClose}>
                  <Link to="/">Home</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to="/sharedleads">Shared</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to="/profile">Profile</Link>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <NavLink to="/" className="text-white">
                <Button color="inherit">Home</Button>
              </NavLink>
              <NavLink to="/sharedleads" className="text-white">
                <Button color="inherit">Shared</Button>
              </NavLink>
              <NavLink to="/profile" className="text-white">
                <Button color="inherit">Profile</Button>
              </NavLink>
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
