import React from "react";
import { Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const ToggleNav = () => {
  const location = useLocation();
  return (
    <>
      <div className="toggle-buttons">
        <Link to="/sharedleads" className="primary-text">
          <Button
            variant={
              location.pathname === "/sharedleads" ? "contained" : "outlined"
            }
          >
            Shared By you
          </Button>
        </Link>
        <Link to="/adminleads" className="text-white">
          <Button
            variant={
              location.pathname === "/adminleads" ? "contained" : "outlined"
            }
          >
            Shared By TCM
          </Button>
        </Link>
      </div>
    </>
  );
};

export default ToggleNav;
