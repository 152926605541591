import { useContext, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Navbar from "../components/Navbar";

const Auth = ({ Component }) => {
  const { url, setUser } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    const tcm_user = Cookies.get("tcm_user");
    if (!tcm_user) {
      navigate("/login", { replace: true });
      return;
    }
    //
    const Authenticate = async () => {
      const res = await axios.get(`${url}/user/auth`, {
        headers: {
          token: tcm_user,
        },
      });
      if (res.data.success === true) {
        setUser(res.data.user);
      }
    };
    Authenticate();
  }, []);
  return (
    <>
      <Navbar />
      <Component />
    </>
  );
};

export default Auth;
