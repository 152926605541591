import React, { useContext } from "react";
import { Button, IconButton, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import axios from "axios";
import { AppContext } from "../context/AppContext";

const EditLead = ({
  editModal,
  setEditModal,
  edits,
  handleEditInputs,
  setEdits,
  getUserLeads,
}) => {
  const { url, getUser } = useContext(AppContext);
  const editLead = async (e) => {
    e.preventDefault();
    const tcm_user = Cookies.get("tcm_user");
    try {
      const res = await axios.put(
        `${url}/user/lead/user/${edits._id}`,
        {
          customerName: edits.customerName,
          customerNumber: edits.customerNumber,
          totalCreditors: edits.totalCreditors,
          missedEMI: edits.missedEMI,
          companyName: edits.companyName,
        },
        {
          headers: {
            token: tcm_user,
          },
        }
      );
      if (res.data.success === true) {
        getUserLeads();
        getUser();
        setEdits(null);
        setEditModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        open={editModal}
        onClose={() => setEditModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 400,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h2>Edit a lead</h2>
            <IconButton aria-label="close" onClick={() => setEditModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <>
            {edits !== null ? (
              <>
                <form>
                  <TextField
                    label="Customer Name"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    name="customerName"
                    value={edits.customerName}
                    onChange={handleEditInputs}
                  />
                  <TextField
                    label="Customer Number"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    name="customerNumber"
                    value={edits.customerNumber}
                    onChange={handleEditInputs}
                  />
                  <TextField
                    label="Company name"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    name="companyName"
                    value={edits.companyName}
                    onChange={handleEditInputs}
                  />
                  <TextField
                    label="Total Creditors"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    name="totalCreditors"
                    value={edits.totalCreditors}
                    onChange={handleEditInputs}
                  />
                  <TextField
                    label="Missed EMI"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    name="missedEMI"
                    value={edits.missedEMI}
                    onChange={handleEditInputs}
                  />
                  <div className="text-end">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={editLead}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </>
            ) : (
              ""
            )}
          </>
        </Box>
      </Modal>
    </>
  );
};

export default EditLead;
