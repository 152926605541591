import React, { useContext } from "react";
import { IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { AppContext } from "../context/AppContext";
import CloseIcon from "@mui/icons-material/Close";

const ViewFile = ({ fileModal, setFileModal, filename }) => {
  const { url } = useContext(AppContext);
  return (
    <>
      <Modal
        open={fileModal}
        onClose={() => setFileModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 400,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <div className="text-end">
            <IconButton aria-label="close" onClick={() => setFileModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <img src={`${url}/${filename}`} alt="" />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ViewFile;
